import React, { useState, useEffect, useRef } from "react";
import { Box, Drawer, List, ListItem, ListItemIcon, Button } from "@mui/material";
import ChatPanel from "./ChatPanel";
import BannerPanel from "./BannerPanel";
import AIAssistantPanel from "./AIAssistantPanel";
import BrandPanel from "./BrandPanel";
import BackgroundPanel from "./BackgroundPanel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faRobot, faImage, faTags, faBagShopping, faUserPlus } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import './Pages.css';
import logo from "./../Assests/dyrect-logo.png";
import MeetingSection from "./MeetingSection";
import { useLocation } from "react-router-dom";

const Studio = () => {
    const [chatMessages, setChatMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [selectedPanel, setSelectedPanel] = useState(null);

    const videoRef = useRef(null);
    const [videoStream, setVideoStream] = useState(null); // Video stream for the camera
    const [audioStream, setAudioStream] = useState(null); // Audio stream for the mic

    useEffect(() => {
        // Access camera and microphone on mount
        const getUserMedia = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                setVideoStream(stream);
                setAudioStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error("Error accessing media devices:", error);
            }
        };

        getUserMedia();

        // Cleanup on unmount
        return () => {
            if (videoStream) {
                videoStream.getTracks().forEach(track => track.stop());
            }
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []); // Only run this once on component mount

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            setChatMessages([...chatMessages, newMessage]);
            setNewMessage("");
        }
    };

    const handleSidebarItemClick = (panel) => {
        setSelectedPanel((prev) => (prev === panel ? null : panel)); // Toggle the panel
    };

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      };

    const query = useQuery();  // useQuery is our custom hook to read query params
    const room = query.get('room');  // Extract the 'room' parameter from the URL

    return (
        <Box display="flex" height="100vh">
            <Box className='theme-bg' width="94%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <div className="col-12">
                    <img src={logo} alt="Dyrect Logo" className="logo-image ml-1" />
                    <Button
                        variant="contained"
                        startIcon={<FontAwesomeIcon icon={faUserPlus} style={{ fontSize: 'small' }} />}
                        style={{ lineHeight: 'inherit', color: 'white' }}
                        className="mr-2 fl-r theme-bg-light"
                    >
                        <span className="text-trans-none">Invite</span>
                    </Button>
                </div>
                <MeetingSection room={room}></MeetingSection>
            </Box>

            <Drawer
                variant="persistent"
                anchor="right"
                open={true}
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#19193e',
                        color: 'white',
                        borderLeft: '10px solid #000029',
                        width: '7%',
                        placeContent: 'center'
                    },
                }}
            >
                <List>
                    {["Banners", "Chat", "AI Assistant", "Brand", "Background"].map((text) => (
                        <ListItem
                            button
                            key={text}
                            onClick={() => handleSidebarItemClick(text)}
                            style={{
                                background: selectedPanel === text ? 'black' : 'transparent',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '10px 0'
                            }}
                            className="pointer"
                        >
                            <ListItemIcon style={{ minWidth: 0, marginBottom: '5px' }}>
                                {/* FontAwesome icons */}
                                {text === "Chat" && <FontAwesomeIcon icon={faComment} color="white" size="1x" />}
                                {text === "Banners" && <FontAwesomeIcon icon={faTags} color="white" size="1x" />}
                                {text === "AI Assistant" && <FontAwesomeIcon icon={faRobot} color="white" size="1x" />}
                                {text === "Brand" && <FontAwesomeIcon icon={faBagShopping} color="white" size="1x" />}
                                {text === "Background" && <FontAwesomeIcon icon={faImage} color="white" size="1x" />}
                            </ListItemIcon>
                            <span style={{ textAlign: 'center', fontSize: 'smaller' }}>{text}</span>
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            <Box p={2} className='theme-bg-light'
                sx={{
                    transition: '0.5s ease, transform 0.5s ease',
                    opacity: selectedPanel ? 1 : 0,
                    transform: selectedPanel ? 'translateX(0)' : 'translateX(20px)',
                    visibility: selectedPanel ? 'visible' : 'hidden',
                    width: selectedPanel !== null ? '40%' : '0%',
                    backgroundColor: '#19193e',
                }}
            >
                {selectedPanel === "Chat" && <ChatPanel chatMessages={chatMessages} setChatMessages={setChatMessages} newMessage={newMessage} setNewMessage={setNewMessage} handleSendMessage={handleSendMessage} />}
                {selectedPanel === "Banners" && <BannerPanel />}
                {selectedPanel === "AI Assistant" && <AIAssistantPanel />}
                {selectedPanel === "Brand" && <BrandPanel />}
                {selectedPanel === "Background" && <BackgroundPanel />}
            </Box>
        </Box>
    );
};

export default Studio;
