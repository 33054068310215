import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AIAssistantPanel = () => {
    return (
        <Box color='white' className="col-9">
            <Typography variant="h6">AI Assistant</Typography>
            <div className="mt-3">
                <Accordion
                    sx={{
                        backgroundColor: '#ffffff10',
                        color: 'white',
                        borderRadius: '10px',
                        borderTopLeftRadius: '10px !important', 
                        borderTopRightRadius: '10px !important'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    >
                        Agenda
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                            Introduction (3-5 minutes)
                            <br />
                            Brief welcome and introduction of hosts
                            Overview of the episode’s topic or theme
                            Quick mention of any sponsors or promotions (if applicable)
                            Main Topic Discussion (20-30 minutes)
                            <br />
                            Introduction of the main topic or guest
                            Breakdown of key points or questions
                            Discussion or interview (with back-and-forth banter, insights, or stories)
                            Listener Questions or Interaction (5-10 minutes)
                            <br />
                            Answer listener questions or feedback from social media
                            Engage with audience comments, polls, or shoutouts
                            Fun Segment (Optional, 5-10 minutes)
                            <br />
                            Quick game, trivia, or lighthearted discussion (e.g., "What’s your favorite [topic]?" or "Rapid-fire questions")
                            Closing Remarks (2-3 minutes)
                            <br />
                            Recap of key points from the episode
                            Mention of next episode or upcoming topics
                            Call to action (subscribe, rate, follow, etc.)
                            Outro (1-2 minutes)
                            <br />
                            Thank you and goodbye
                            Music fade out
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div className="bannerData pointer mt-3">
                    <Typography variant="h8">AI Suggestions</Typography>
                    <br />
                    AI Suggestions
                </div>
                <div className="bannerData pointer">
                    <Typography variant="h8">AI Tracking</Typography>
                    <br />
                    AI Tracking
                </div>
            </div>
        </Box>
    );
};

export default AIAssistantPanel;
