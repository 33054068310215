import React, { useState } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

const ChatPanel = ({ chatMessages, setChatMessages }) => {

    // Initialize messageData with the state
    const [messages, setMessages] = useState([
        { senderName: 'Chaitu', message: 'Hi' },
        { senderName: 'Raghu', message: 'Hey' },
        { senderName: 'Chaitu', message: 'How are you?' },
        { senderName: "Raghu", message: "I'm great... how about you?" }
    ]);

    const [newMessage, setNewMessage] = useState('');

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return; // Prevent sending empty messages

        const newMessageData = { senderName: 'Chaitu', message: newMessage };

        // Update the state with the new message
        setMessages([...messages, newMessageData]);

        // Clear the input field after sending the message
        setNewMessage('');
    };

    return (
        <Box color='white' className="col-9" display="flex" flexDirection="column" height="100%">
            <Box flex="1" overflow="auto">
                <Typography variant="h6" className="mb-2">Chat</Typography>
                {messages.map((banner, index) => (
                    <Box key={index} className="bannerData pointer">
                        <Typography variant="body2">
                            {banner.senderName}
                        </Typography>
                        <Typography variant="body1">
                            {banner.message}
                        </Typography>
                    </Box>
                ))}
            </Box>

            {/* Message Input Section at the bottom */}
            <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                <TextField
                    variant="filled"
                    fullWidth
                    label="Type your message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    className="message-input theme-bg-light"
                    InputLabelProps={{ style: { color: 'white' } }}
                    sx={{
                        color: 'white',
                        borderRadius: 1,
                    }}
                    InputProps={{
                        style: { color: 'white' },
                        endAdornment: (
                            <InputAdornment position="end">
                                <SendIcon
                                    onClick={handleSendMessage}
                                    style={{ color: 'white', cursor: 'pointer' }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};

export default ChatPanel;
