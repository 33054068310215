import React from "react";
import { Box, Typography } from "@mui/material";

const BrandPanel = () => {
  return (
    <Box color='white'>
      <Typography variant="h6">BrandPanel</Typography>
      <Typography variant="body2">Manage BrandPanel here.</Typography>
    </Box>
  );
};

export default BrandPanel;
