import React from 'react'
import SideBar from '../Components/Sidebar'

const Community = () => {
    return (
        <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
            <SideBar></SideBar>
            <div className='container-fluid overflow-x-none'>
                <div className='row'>
                    <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                        <div className="row pt-5">
                            <div className="container-fluid text-white">
                                Community
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Community