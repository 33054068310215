import React, { useState } from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import image1 from "../Assests/image1.png";
import image2 from "../Assests/image2.png";
import image3 from "../Assests/image3.png";

const BackgroundPanel = () => {
    // Create an array of images with their names
    const images = [
        { src: image1, name: "image1" },
        { src: image2, name: "image2" },
        { src: image3, name: "image3" }
    ];

    // State to track selected image
    const [selectedImage, setSelectedImage] = useState("");

    // Handle radio button change
    const handleRadioChange = (event) => {
        setSelectedImage(event.target.value);
    };

    return (
        <Box color="white" className="col-9">
            <Typography variant="h6">Background</Typography>

            {/* Loop through the images to render each with a radio button on top left */}
            {images.map((image, index) => (
                <div key={index} className="my-3" style={{ position: 'relative' }}>
                    {/* Position the radio button at the top left */}
                    <Radio
                        value={image.name}
                        checked={selectedImage === image.name}
                        onChange={handleRadioChange}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            left: '5px',
                            zIndex: 1,
                            color: 'white'
                        }}
                    />
                    {/* Image itself */}
                    <img src={image.src} alt={image.name} style={{ width: '100%', borderRadius: '8px' }} />
                </div>
            ))}

            {/* Display the selected image name */}
            {selectedImage && (
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                    Selected Image: {selectedImage}
                </Typography>
            )}
        </Box>
    );
};

export default BackgroundPanel;
