import React, { useState } from "react";
import { TextField, Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook, faApple } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthService, confirmSignUp, signUp } from "../Services/AuthService"; // Assume AuthService handles Cognito signup/confirmation
import logo from "./../Assests/dyrect-logo.png";
import "./Pages.css";

function Signup() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);

  // Add the Cognito hosted domain and app client details here
  const cognitoDomain = "dyrect-ai.auth.us-west-1.amazoncognito.com"; // e.g., myapp.auth.us-east-1.amazoncognito.com
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = "http://localhost:3000/signup"; // Make sure this matches your callback URL in Cognito
  const googleProvider = "Google";

  const handleSignup = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      // const data = await AuthService.signUp(username, email, password);
      signUp(username, email, password)
        .then(result => {
          console.log('User signed up:', result);
          setIsConfirming(true);
        })
        .catch(err => {
          console.error('Sign-up error:', err);
        });


      // if (data.UserConfirmed === false) {
      //   setIsConfirming(true);
      // } else {
      //   alert("Error signing up: " + data.message);
      // }
    } catch (err) {
      console.error("Signup error:", err);
      alert("Error signing up: " + err.message);
    }
  };

  const handleConfirmSignup = async (event) => {
    event.preventDefault();
    try {
      // const data = await AuthService.confirmSignUp(username, confirmationCode);

      // if (data.message) {
      //   navigate("/login");
      // } else {
      //   alert("Error confirming sign-up: " + (data.message || "Unknown error"));
      // }
      confirmSignUp(username, confirmationCode)
        .then(result => {
          console.log('User confirmed:', result);
          navigate("/login");
        })
        .catch(err => {
          console.error('Confirm sign-up error:', err);
        });

    } catch (err) {
      console.error("Confirmation error:", err);
      alert("An error occurred during confirmation. Please try again later.");
    }
  };

  // Redirects to the Cognito hosted UI for Google Sign-In
  const handleGoogleSignup = () => {
    const googleLoginUrl = `https://${cognitoDomain}/oauth2/authorize?identity_provider=${googleProvider}&redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&scope=openid+profile+email`;
    window.location.href = googleLoginUrl;
  };

  const login = () => {
    navigate("/login");
  };

  return (
    <div className="height-100 clr-white">
      <div className="container-fluid">
        <div className="row theme-bg">
          <div className="col-xl-7 col-lg-6">
            <div className="logo-container">
              <img src={logo} alt="Dyrect Logo" className="logo-image" />
              <span className="logo-text">yrect</span>
            </div>
            <div className="welcome-grid height-100">Unlock the Experience!</div>
            <div className="ellipse" />
          </div>
          <div className="col-xl-5 col-lg-6" style={{ zIndex: "1000" }}>
            <div className="login-grid m-5">
              <h2 className="login-title">{isConfirming ? "Confirm Sign-Up" : "Sign-Up"}</h2>
              <p className="login-subtitle">
                {isConfirming
                  ? "Enter the confirmation code sent to your email."
                  : "Just some details to get you in!"}
              </p>

              {isConfirming ? (
                <>
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    label="Confirmation Code"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setConfirmationCode(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="login-button"
                    onClick={handleConfirmSignup}
                  >
                    Confirm Sign-Up
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="login-button"
                    onClick={handleSignup}
                  >
                    Sign Up
                  </Button>
                </>
              )}

              <div className="or-section">
                <span>Or</span>
              </div>
              <div className="social-icons">
                <IconButton aria-label="Google" className="social-icon google" onClick={handleGoogleSignup}>
                  <FontAwesomeIcon icon={faGoogle} style={{ color: "#db4437", fontSize: "1.5em" }} />
                </IconButton>
                <IconButton aria-label="Facebook" className="social-icon facebook" onClick={() => alert("Facebook clicked")}>
                  <FontAwesomeIcon icon={faFacebook} style={{ color: "#4267B2", fontSize: "1.5em" }} />
                </IconButton>
                <IconButton aria-label="Apple" className="social-icon apple" onClick={() => alert("Apple clicked")}>
                  <FontAwesomeIcon icon={faApple} style={{ color: "#ffffff", fontSize: "1.5em" }} />
                </IconButton>
              </div>
              <p className="signup-text">
                Already registered? <span onClick={login}>Login</span>
              </p>
              <div className="footer-links">
                <a href="javascript:void(0)">Terms & Conditions</a> <a href="javascript:void(0)">Support</a>{" "}
                <a href="javascript:void(0)">Customer Care</a>
              </div>
            </div>
          </div>
          <div className="bottom-ellipse"></div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
