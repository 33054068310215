import { useNavigate } from 'react-router-dom';
import React from 'react'
import SideBar from '../Components/Sidebar'
import './Pages.css'
import { Button } from '@mui/material';

const Schedules = () => {
    const navigate = useNavigate();

    const podcasts = [
        {
            title: "My First Podcast",
            host: "John Doe",
            date: "21st November, 15:30 (GMT - 5:00)",
            guests: ["Christopher Nolan", "James Cameron"]
        },
        {
            title: "Chill Vibes",
            host: "John Doe",
            date: "27th November, 11:30 (GMT - 5:00)",
            guests: ["Christopher Nolan", "James Cameron"]
        },
        {
            title: "Creative Insights",
            host: "John Doe",
            date: "1st December, 12:30 (GMT - 5:00)",
            guests: ["Christopher Nolan", "James Cameron"]
        }
    ];

    const handleSchedule = () => {
        navigate('/schedules/create')
    }

    return (
        <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
            <SideBar></SideBar>
            <div className='container overflow-x-none'>
                <div className='row'>
                    <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                        <div className="row pt-5">
                            <div className="col-6 mb-4">
                                <h2>Create Podcast</h2>
                            </div>
                            <div className="col-6">
                                <Button className="btn fl-r schedule-btn">
                                    <span className='text-trans-none'>Record</span>
                                </Button>
                                <Button
                                    className="btn fl-r schedule-btn"
                                    style={{
                                        marginRight: "10px"
                                    }}
                                    onClick={handleSchedule}
                                >
                                    <span className='text-trans-none'>Schedule</span>
                                </Button>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                {podcasts.map((podcast, index) => (
                                    <div
                                        key={index}
                                        className="card mb-3"
                                        style={{
                                            backgroundColor: "rgb(25 25 62)",
                                            color: "#fff",
                                            borderRadius: "10px",
                                            border: "none"
                                        }}
                                    >
                                        <div className="row card-body">
                                            <div className='col-6'>
                                                <h5 className="card-title" style={{ fontSize: "20px" }}>{podcast.title}</h5>
                                            </div>
                                            <div className='col-3'>
                                                <p className="card-text">
                                                    <strong>Host:</strong> {podcast.host}<br />
                                                    <strong>Date/Time:</strong> {podcast.date}<br />
                                                </p>
                                            </div>
                                            <div className='col-3'>
                                                <p className="card-text">
                                                    <strong>Guests:</strong> {podcast.guests.join(", ")}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Schedules