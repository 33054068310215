import React from "react";
import { Box, Typography } from "@mui/material";
import './Pages.css'

const BannerPanel = () => {

    const bannerData = [
        'This is my First Banner',
        'This is my long second Banner.',
        'This is my Third very long Banner to move to the second line.'
    ]

    return (
        <Box color='white' className="col-9">
            <Typography variant="h6" className="mb-2">Banners</Typography>
            {bannerData.map((banner, index) => (
                <div className="bannerData pointer">
                    {banner}
                </div>
            ))}
        </Box>
    );
};

export default BannerPanel;
