import { Box, Button, Container, IconButton, TextField, Typography } from "@mui/material";
import * as AWS from "aws-sdk/global";
import * as Chime from "aws-sdk/clients/chime";
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  MessagingSessionConfiguration,
  DefaultMessagingSession,
} from "amazon-chime-sdk-js";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  InvisibleAudio,
  PeerBox,
  SectionBox,
  Video,
} from "./ui-components";
import { faStopCircle, faWindowClose, faArrowUpFromBracket, faCircleDot } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import {
  faMicrophone,
  faMicrophoneAltSlash,
  faVideo,
  faVideoSlash,
  faPhoneSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

// import { useRef, useEffect, useState } from "react";

export default function MeetingSection({ room }) {
  const [joining, setJoining] = useState("");
  const [hadFinishedApplication, setFinishedApplication] = useState(false);
  const [meetingSession, setMeetingSession] = useState(null);
  const [hasStartedMediaInputs, setStartedMediaInputs] = useState(false);
  const [hasJoined, setHasJoined] = useState(false);

  const hasJoinedRef = useRef(false);  // Ref to track if the join process has been completed

  const handleJoin = () => {
    console.log('handleJoin called');
    const joiningFormDt = { room: room };
    setJoining(joiningFormDt.room);
    createMeetingSession(joiningFormDt)
      .then((it) => setMeetingSession(it))
      .catch(() => setJoining(""));
  };

  useEffect(() => {
    if (!hasJoinedRef.current && room) {
      handleJoin();
      setHasJoined(true);  // Mark as joined
      hasJoinedRef.current = true;  // Set the ref to true to prevent re-triggering
    }
  }, [room]);  // Only depend on `room`, since `hasJoinedRef` is handled with a ref

  useEffect(() => {
    if (!meetingSession) return;

    const setupInput = async ({ audioId, videoId } = {}) => {
      if (!audioId || !videoId) {
        throw new Error("No video nor audio input detected.");
      }

      if (audioId) {
        const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
        if (audioInputDevices.length) {
          await meetingSession.audioVideo.startAudioInput(audioId);
        }
      }

      if (videoId) {
        const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
        if (videoInputDevices.length) {
          const defaultVideoId = videoInputDevices[0].deviceId;
          await meetingSession.audioVideo.startVideoInput(videoId === "default" ? defaultVideoId : videoId);
          setStartedMediaInputs(true);
        }
      }
    };

    setupInput({ audioId: "default", videoId: "default" }).then(() => {
      meetingSession.audioVideo.start();
    });
  }, [meetingSession]);

  const isInSession = !!(meetingSession && hasStartedMediaInputs);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box width="100%" height="90vh" overflow="hidden">
        <Box component="main" display="flex" flexDirection="column">
          {!hadFinishedApplication && !isInSession && !joining && (
            <Container maxWidth="xs">
              <JoiningMeeting onJoin={handleJoin} />
            </Container>
          )}
          {!hadFinishedApplication && !isInSession && joining && (
            <Container maxWidth="xs">
              <SectionBox heading="Joining...">
                Attempting to join <code>{joining}</code> meeting.
              </SectionBox>
            </Container>
          )}
          {hadFinishedApplication && (
            <Container maxWidth="xs">
              <SectionBox heading="Bye, bye!">
                You can close this window now or...{" "}
                <Button variant="text" onClick={() => window.location.reload()}>
                  start another meeting
                </Button>
              </SectionBox>
            </Container>
          )}
          {!hadFinishedApplication && isInSession && (
            <>
              <StreamingVideosSection meetingSession={meetingSession} />
              <AudioOutput meetingSession={meetingSession} />
              <PinnedVideoSection />
              <Controls
                meetingSession={meetingSession}
                room={joining}
                onLeave={() => setFinishedApplication(true)}
              />
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}


const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const logger = new ConsoleLogger("Logger", LogLevel.INFO);
const deviceController = new DefaultDeviceController(logger);

async function createMeetingSession({ room }) {
  const params = new URLSearchParams([["room", room]]);
  const meetingSessionResponse = await axios.get(
    "http://127.0.0.1:3001/chime-integration/meeting-session",
    {
      params,
    }
  );

  const { meetingResponse, attendeeResponse } = meetingSessionResponse.data;
  const configuration = new MeetingSessionConfiguration(
    meetingResponse,
    attendeeResponse
  );
  const meetingSession = new DefaultMeetingSession(
    configuration,
    logger,
    deviceController
  );

  const messagingSessionResponse = await axios.get(
    `http://127.0.0.1:3001/chime-integration/messaging-session/${meetingResponse.Meeting.MeetingId}`
  );
  const {
    msgChannelMembershipResponse,
    endpointResponse,
    accessKeyId,
    secretAccessKey,
    region,
  } = messagingSessionResponse.data;
  const chime = new Chime({
    region,
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      // sessionToken: "sessionToken"
    },
  });
  const messagingConfiguration = new MessagingSessionConfiguration(
    msgChannelMembershipResponse.Member.Arn,
    meetingResponse.Meeting.MeetingId,
    endpointResponse.Endpoint.Url,
    chime,
    AWS
  );
  window.messagingConfiguration = messagingConfiguration;
  const messagingSession = new DefaultMessagingSession(
    messagingConfiguration,
    logger
  );
  messagingSession.addObserver({
    messagingSessionDidStart: () => {
      console.log("Messaging Connection started!");
    },
    messagingSessionDidReceiveMessage: (message) => {
      console.log("Messaging Connection received message", message);
    },
  });
  window.messagingSession = messagingSession;

  window.sendMessage = async function sendMessage(content) {
    return await axios.post("http://127.0.0.1:3001/chime-integration/message", {
      channelMembership: msgChannelMembershipResponse,
      content,
    });
  };

  window.meetingSession = meetingSession;
  messagingSession.start();
  return meetingSession;
}

function JoiningMeeting({ onJoin }) {
  const handleSubmit = (event) => {
    // event.preventDefault();
    // const roomValue = event.target.room.value;
    
    // if (roomValue && !onJoin) {
    //   onJoin(roomValue); // Make sure onJoin only gets called once with a valid room ID
    // }
  };

  return (
    <SectionBox>
      <Typography component="p" variant="body1" marginTop="10px">
        Start or join a conference room.
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          name="room"
          label="Conference room"
          placeholder="Enter any alphanumeric id..."
          maxLength="64"
          minLength="2"
          margin="normal"
          fullWidth
          required
        />
        <Button type="submit" variant="contained" fullWidth>
          Start call
        </Button>
      </Box>
    </SectionBox>
  );
}

function Controls({ meetingSession, room, onLeave }) {
  const [audible, setAudible] = useState(true);
  const [isStreaming, setStreaming] = useState(true);
  const [recording, setRecording] = useState(false);
  const [pipelineId, setPipelineId] = useState(null);
  const navigate = useNavigate();

  const muteAudio = () => {
    meetingSession.audioVideo.realtimeMuteLocalAudio();
    setAudible(false);
  };

  const unmuteAudio = () => {
    meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    setAudible(true);
  };

  const muteVideo = () => {
    meetingSession.audioVideo.stopVideoInput();
    setStreaming(false);
  };

  const unmuteVideo = async () => {
    const videoInputDevices =
      await meetingSession.audioVideo.listVideoInputDevices();

    if (videoInputDevices.length) {
      const defaultVideoId = videoInputDevices[0].deviceId;
      await meetingSession.audioVideo.startVideoInput(defaultVideoId);
    }
    meetingSession.audioVideo.startLocalVideoTile();
    setStreaming(true);
  };

  const startRecording = async () => {
    try {
      const response = await axios.post(
        "http://127.0.0.1:3001/chime-integration/start-recording",
        {
          meetingId: "e1af3a05-4526-4175-ba06-ad10596e2713",
        }
      );
      setPipelineId(response.data.pipelineId);
      setRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = async () => {
    try {
      await axios.post(
        "http://127.0.0.1:3001/chime-integration/stop-recording",
        { pipelineId }
      );
      setRecording(false);
      setPipelineId(null);
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  const [isScreenSharing, setIsScreenSharing] = useState(false);

  const startScreenShare = async () => {
    try {
      await meetingSession.audioVideo.startContentShareFromScreenCapture();
      setIsScreenSharing(true);
      console.log("Started screen sharing");
    } catch (error) {
      console.error("Error starting screen share:", error);
    }
  };

  const stopScreenShare = async () => {
    try {
      await meetingSession.audioVideo.stopContentShare();
      setIsScreenSharing(false);
      console.log("Stopped screen sharing");
    } catch (error) {
      console.error("Error stopping screen share:", error);
    }
  };

  const stopCall = async () => {
    meetingSession.audioVideo.stopVideoInput();
    meetingSession.audioVideo.stop();
    onLeave();
    navigate('/shows');
  };

  return (
    <Box mt={3} display="flex" justifyContent="center" color="white" gap={3}>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          onClick={recording ? stopRecording : startRecording}
          color="inherit"
          variant="contained"
          className="record-btn"
          style={{ lineHeight: 'inherit' }}
          startIcon={<FontAwesomeIcon icon={recording ? faStopCircle : faCircleDot} size="1x" />}
        >
          <span className="text-trans-none">Record</span>
        </Button>
        <Typography variant="body2" color="white">Start</Typography>
      </Box>

      {/* Mic (Mute/Unmute Audio) */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton
          onClick={audible ? muteAudio : unmuteAudio}
          color="inherit"
          className="studio-icons"
        >
          <FontAwesomeIcon icon={audible ? faMicrophoneAltSlash : faMicrophone} size="1x" />
          {/* <span className="text-trans-none">{audible ? 'Mute' : 'Unmute'} Audio</span> */}
        </IconButton>
        <Typography variant="body2" color="white">Mic</Typography>
      </Box>

      {/* Video (Mute/Unmute Video) */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton
          onClick={isStreaming ? muteVideo : unmuteVideo}
          color="inherit"
          className="studio-icons"
        >
          <FontAwesomeIcon icon={isStreaming ? faVideoSlash : faVideo} size="1x" />
          {/* <span className="text-trans-none">{isStreaming ? 'Mute' : 'Unmute'} Video</span> */}
        </IconButton>
        <Typography variant="body2" color="white">Cam</Typography>
      </Box>

      {/* Screen Share (Start/Stop Screen Share) */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton
          onClick={isScreenSharing ? stopScreenShare : startScreenShare}
          color="inherit"
          className="studio-icons"
        >
          <FontAwesomeIcon icon={isScreenSharing ? faWindowClose : faArrowUpFromBracket} size="1x" />
          {/* <span className="text-trans-none">{isScreenSharing ? 'Stop' : 'Start'} Screen Share</span> */}
        </IconButton>
        <Typography variant="body2" color="white">Share</Typography>
      </Box>

      {/* Leave (End Call) */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton
          onClick={stopCall}
          color="error"
          className="studio-icons"
        >
          <FontAwesomeIcon icon={faPhoneSlash} size="1x" />
          {/* <span className="text-trans-none">End Call</span> */}
        </IconButton>
        <Typography variant="body2" color="white">End</Typography>
      </Box>
    </Box>

  );
}

function AudioOutput({ meetingSession }) {
  const audioRef = useRef(null);

  useEffect(() => {
    if (!audioRef.current) {
      console.error("No audio element found.");
      return;
    }

    const audioElement = audioRef.current;
    meetingSession.audioVideo.bindAudioElement(audioElement);
  }, [meetingSession]);

  return <InvisibleAudio ref={audioRef} />;
}

function StreamingVideosSection({ meetingSession }) {
  const localVideoRef = useRef(null);

  // useEffect(() => {
  //   if (!localVideoRef.current) {
  //     console.error("No local video element found.");
  //     return;
  //   }

  //   const videoElement = localVideoRef.current;

  //   const observer = {
  //     videoTileDidUpdate: (tileState) => {
  //       if (!tileState.boundAttendeeId || !tileState.localTile) {
  //         return;
  //       }

  //       meetingSession.audioVideo.bindVideoElement(
  //         tileState.tileId,
  //         videoElement
  //       );
  //       videoElement.id = `video-${tileState.boundAttendeeId}`;
  //     },
  //   };

  //   meetingSession.audioVideo.addObserver(observer);

  //   meetingSession.audioVideo.startLocalVideoTile();
  // }, [meetingSession]);

  const screenShareRef = useRef(null);
  const [isSharingScreen, setIsSharingScreen] = useState(false);

  useEffect(() => {
    if (!meetingSession) {
      console.error("Meeting session is not available.");
      return;
    }

    const observer = {
      videoTileDidUpdate: (tileState) => {
        console.log("Tile Updated:", tileState);

        if (tileState.localTile && !tileState.isContent) {
          // Bind local video stream to local video element
          if (localVideoRef.current) {
            meetingSession.audioVideo.bindVideoElement(
              tileState.tileId,
              localVideoRef.current
            );
            localVideoRef.current.id = `video-${tileState.boundAttendeeId}`;
          } else {
            console.error("Local video element is not available.");
          }
        } else if (tileState.isContent) {
          // Bind screen share stream to screenShareRef
          console.log("localVideoRef", localVideoRef);
          console.log("screenShareRef", screenShareRef);
          setIsSharingScreen(true);
          if (screenShareRef.current) {
            console.log("Screen share stream detected, binding...");
            meetingSession.audioVideo.bindVideoElement(
              tileState.tileId,
              screenShareRef.current
            );
          } else {
            console.error("Screen share element is not available.");
          }
        }
      },
      videoTileWasRemoved: (tileId) => {
        console.log("Tile Removed:", tileId);
        if (isSharingScreen) setIsSharingScreen(false);
      },
    };

    meetingSession.audioVideo.addObserver(observer);
    meetingSession.audioVideo.startLocalVideoTile();

    return () => {
      meetingSession.audioVideo.removeObserver(observer);
    };
  }, [meetingSession, screenShareRef.current]);

  const videoSlotsRef = useRef(
    Array(25)
      .fill()
      .map(() => ({ tileId: null, video: null }))
  );

  const [enabledTiles, setEnabledTiles] = useState([]);
  const enableTile = (tileId) =>
    setEnabledTiles((previous) => [...previous, tileId]);
  const disableTile = (tileId) =>
    setEnabledTiles((previous) => previous.filter((p) => p !== tileId));
  const isEnabledTile = (tileId) => enabledTiles.includes(tileId);

  useEffect(() => {
    const findSlot = (tileId) =>
      videoSlotsRef.current.find((slot) => slot.tileId === tileId) ||
      videoSlotsRef.current.find((slot) => !slot.tileId);
    const mapToAssignedSlot = (assigningTileId, assigningSlot) =>
      videoSlotsRef.current.map((slot) =>
        slot.video === assigningSlot.video
          ? { ...slot, tileId: assigningTileId }
          : slot
      );
    const mapToUnassignedSlot = (unassigningTileId) =>
      videoSlotsRef.current.map((slot) =>
        slot.tileId === unassigningTileId ? { ...slot, tileId: null } : slot
      );

    const mutateVideoSlotsRef = (updatingSlots) => {
      videoSlotsRef.current = updatingSlots;
    };

    const observer = {
      videoTileDidUpdate: (tileState) => {
        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent
        ) {
          return;
        }

        const slot = findSlot(tileState.tileId);
        if (!slot) {
          throw new Error("Failed to find slot for remote peer.");
        }

        mutateVideoSlotsRef(mapToAssignedSlot(tileState.tileId, slot));

        if (tileState.active) {
          enableTile(tileState.tileId);
        }

        meetingSession.audioVideo.bindVideoElement(
          tileState.tileId,
          slot.video
        );
        slot.video.id = `video-${tileState.boundAttendeeId}`;
      },
      videoTileWasRemoved: (tileId) => {
        mutateVideoSlotsRef(mapToUnassignedSlot(tileId));
        disableTile(tileId);
      },
    };

    meetingSession.audioVideo.addObserver(observer);
  }, [meetingSession]);

  return (
    <SectionBox
      aria-label="Streaming videos"
      display="flex"
      justifyContent="center"
    >
      <Box>
        <PeerBox title="Screen Share" enabled={isSharingScreen}>
          <Video
            ref={screenShareRef}
            className="streaming-video screen-share-video"
          />
        </PeerBox>
        <PeerBox title="Local user" enabled>
          <Video
            ref={localVideoRef}
            className="streaming-video streaming-video-local"
          />
        </PeerBox>
        {videoSlotsRef.current.map((slot, index) => (
          <PeerBox
            key={index}
            title={`Remote user #${index}`}
            enabled={isEnabledTile(slot.tileId)}
          >
            <Video
              ref={(video) => (slot.video = video)}
              className="streaming-video streaming-video-remote"
            />
          </PeerBox>
        ))}
      </Box>
    </SectionBox>
  );
}

function PinnedVideoSection() {
  const videoRef = useRef(null);

  useEffect(() => {
    const workerId = setInterval(() => {
      if (videoRef.current.srcObject && videoRef.current.srcObject.active) {
        return;
      }

      const foundActiveStreamingElement = Array.from(
        document.getElementsByClassName("streaming-video")
      ).find((el) => el.srcObject && el.srcObject.active);
      copyStreamToPinnedVideo(foundActiveStreamingElement, videoRef.current);
    }, 3000);
    return () => clearInterval(workerId);
  }, []);

  return (
    <Video
      ref={videoRef}
      id="video-pinned"
      aria-label="Pinned video"
      style={{ maxHeight: "50vh", objectFit: "none" }}
      width={undefined}
      height={undefined}
    />
  );
}

function copyStreamToPinnedVideo(
  originatingVideoElement,
  pinnedVideoElement = document.getElementById("video-pinned")
) {
  if (!originatingVideoElement || !originatingVideoElement.srcObject) {
    console.error(
      "Invalid originating video element/stream",
      originatingVideoElement
    );
    return;
  }

  if (!pinnedVideoElement) {
    console.error("Invalid pinned video element", pinnedVideoElement);
    return;
  }

  if (pinnedVideoElement.srcObject === originatingVideoElement.srcObject) {
    return;
  }

  pinnedVideoElement.muted = true;
  pinnedVideoElement.volume = 0;
  pinnedVideoElement.setAttributeNode(document.createAttribute("autoplay"));
  pinnedVideoElement.setAttributeNode(document.createAttribute("playsinline"));
  pinnedVideoElement.srcObject = originatingVideoElement.srcObject;
}
