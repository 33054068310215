import React, { useState } from 'react';
import { TextField, Chip, Button as MUIButton, IconButton, InputAdornment, Button, TextareaAutosize } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import './Pages.css'; // Additional custom styling, if needed
import SideBar from '../Components/Sidebar';
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';

const Schedule = () => {

    const navigate = useNavigate();
    const [guestEmails, setGuestEmails] = useState([]);
    const [emailInput, setEmailInput] = useState("");

    const handleAddGuestEmail = () => {
        if (emailInput.trim()) {
            setGuestEmails([...guestEmails, emailInput.trim()]);
            setEmailInput("");
        }
    };

    const handleDeleteGuestEmail = (emailToDelete) => {
        setGuestEmails(guestEmails.filter(email => email !== emailToDelete));
    };

    const handleJoinStudio = () => {
        navigate("/schedules/join-studio");
    }

    return (
        <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
            <SideBar></SideBar>
            <div className='container-fluid overflow-x-none'>
                <div className='row'>
                    <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                        <div className="row pt-5">
                            <div className="container-fluid text-white">
                                <div className="container-fluid p-5 text-white mb-4 pb-0" style={{ overflowY: 'auto', height: '81vh' }}>
                                    <div className="row mb-4">
                                        <div className="col-md-8">
                                            <TextField
                                                fullWidth
                                                label="Title"
                                                variant="filled"
                                                placeholder="Enter title"
                                                InputProps={{ style: { color: 'white' } }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                            />
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="col-md-2">
                                                <DateTimePicker
                                                    label="Start Date & Time"
                                                    variant="filled"
                                                    sx={{
                                                        "& .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input": { color: 'white' }, // Label color on focus
                                                        "& .MuiSvgIcon-root": { color: 'white' },
                                                        "& .MuiFormLabel-root ": { color: 'white' },
                                                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                        borderRadius: 1,
                                                        width: '100%',
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-2">
                                                <DateTimePicker
                                                    label="End Date & Time"
                                                    variant="filled"
                                                    sx={{
                                                        "& .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input": { color: 'white' }, // Label color on focus
                                                        "& .MuiSvgIcon-root": { color: 'white' },
                                                        "& .MuiFormLabel-root ": { color: 'white' },
                                                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                        borderRadius: 1,
                                                        width: '100%',
                                                    }}
                                                    renderInput={() => (
                                                        <TextField
                                                            variant="filled" />
                                                    )}
                                                />
                                            </div>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Guest Email"
                                                variant="filled"
                                                placeholder="Enter guest emails.."
                                                value={emailInput}
                                                onChange={(e) => setEmailInput(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', maxWidth: '100%' }}
                                                        >
                                                            {guestEmails?.map((email, index) => (
                                                                <Chip
                                                                    key={index}
                                                                    label={email}
                                                                    onDelete={() => handleDeleteGuestEmail(email)}
                                                                    style={{ marginRight: 4, background: '#818181', color: 'white' }}
                                                                />
                                                            ))}
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleAddGuestEmail} color="primary">
                                                                <AddIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    style: { color: 'white', padding: '1%' },
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{
                                                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                    borderRadius: 1,
                                                    paddingBottom: '8px' // Adjust as needed for multiline spacing
                                                }}
                                            />


                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Agenda"
                                                variant="filled"
                                                multiline
                                                placeholder="Enter agenda details here"
                                                InputProps={{
                                                    style: { color: 'white' },
                                                    inputComponent: TextareaAutosize,
                                                    inputProps: { minRows: 3 }, // Adjust the minimum number of rows as needed
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{
                                                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                    borderRadius: 1
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Guest Info"
                                                variant="filled"
                                                placeholder="Enter guest details here"
                                                multiline
                                                InputProps={{
                                                    style: { color: 'white' },
                                                    inputComponent: TextareaAutosize,
                                                    inputProps: { minRows: 2 }, // Adjust the minimum number of rows as needed
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Important Links"
                                                variant="filled"
                                                placeholder="Enter any important links here"
                                                multiline
                                                InputProps={{
                                                    style: { color: 'white' },
                                                    inputComponent: TextareaAutosize,
                                                    inputProps: { minRows: 2 }, // Adjust the minimum number of rows as needed
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12 d-flex justify-content-end px-4r">
                                        <Button
                                            variant="contained"
                                            className='mr-2'
                                            sx={{ bgcolor: 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', color: 'white' }}
                                                onClick={handleJoinStudio}
                                        >
                                            <span className='text-trans-none'>Join Studio</span>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            // color="secondary"
                                            sx={{ bgcolor: 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', color: 'white' }}
                                        >
                                            <span className='text-trans-none'>Save and Send</span>

                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Schedule;
