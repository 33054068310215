import React, { useState, useEffect, useRef } from "react";
import "./Pages.css";
import SideBar from "../Components/Sidebar";
import { FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import { TextField, Button } from "@mui/material";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import VideocamOffRoundedIcon from '@mui/icons-material/VideocamOffRounded';
import { useNavigate } from "react-router-dom";

const JoinStudio = () => {

    const navigate = useNavigate();
    const [hostname, setHostname] = useState("John Doe");
    const [showName, setShowName] = useState("My First Podcast");
    const [cameras, setCameras] = useState([]);
    const [microphones, setMicrophones] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState("");
    const [selectedMicrophone, setSelectedMicrophone] = useState("");
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    // State to manage microphone and video toggle
    const [isMicOn, setIsMicOn] = useState(true);
    const [isVideoOn, setIsVideoOn] = useState(true);

    // Request permissions and fetch devices
    const getMediaDevices = async () => {
        try {
            // Request permissions for camera and microphone
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });

            // Set the stream for video preview
            setStream(mediaStream);

            // Get the list of media devices
            const devices = await navigator.mediaDevices.enumerateDevices();

            // Filter video input (camera) and audio input (microphone) devices
            const videoDevices = devices.filter((device) => device.kind === "videoinput");
            const audioDevices = devices.filter((device) => device.kind === "audioinput");

            setCameras(videoDevices);
            setMicrophones(audioDevices);

            // Set default selections
            if (videoDevices.length > 0) setSelectedCamera(videoDevices[0].deviceId);
            if (audioDevices.length > 0) setSelectedMicrophone(audioDevices[0].deviceId);
        } catch (error) {
            console.error("Error accessing media devices:", error);
        }
    };

    // Toggle microphone
    const handleMicToggle = () => {
        setIsMicOn((prevState) => {
            if (videoRef.current) {
                videoRef.current.muted = prevState; // Use the previous state value
            }
            return !prevState; // Return the new state
        });
    };

    // Toggle video
    const handleVideoToggle = () => {
        setIsVideoOn((prevState) => {
            if (videoRef.current) {
                const tracks = videoRef.current.srcObject?.getVideoTracks();
                if (tracks && tracks.length > 0) {
                    tracks[0].enabled = !prevState; // Use the inverse of the previous state
                }
            }
            return !prevState; // Return the new state
        });
    };

    const handleJoin = () => {
        navigate('/studio')
    }


    // Play the video stream
    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    // Fetch devices on component mount
    useEffect(() => {
        getMediaDevices();
    }, []);

    return (
        <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
            <SideBar></SideBar>
            <div className='container-fluid overflow-x-none'>
                <div className='row'>
                    <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                        <div className="row pt-5">
                            <div className="container-fluid text-white">
                                <div className="container-fluid p-5 text-white mb-4 pb-0" style={{ overflowY: 'auto', height: '87vh' }}>
                                    <div className="row theme-bg-light p-3">
                                        <h2>Create Podcast</h2>
                                        <p>Let's check your video and audio</p>
                                        <div className="col-5 studio-content">
                                            {/* Video Preview */}
                                            <div className="video-preview" >
                                                <div className="video-container" style={{ position: "relative" }}>
                                                    <video
                                                        ref={videoRef}
                                                        autoPlay
                                                        playsInline
                                                        muted
                                                        className="preview-video mb-2"
                                                    />
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "20px",
                                                            left: "50%",
                                                            transform: "translateX(-50%)",
                                                            display: "flex",
                                                            gap: "20px",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                                                            padding: "10px 20px",
                                                            borderRadius: "20px",
                                                            zIndex: 10, // Ensure the overlay is above the video
                                                        }}
                                                    >
                                                        {/* Microphone Toggle */}
                                                        {isMicOn ? (
                                                            <MicIcon onClick={handleMicToggle} style={{ cursor: "pointer", color: "#fff" }} />
                                                        ) : (
                                                            <MicOffIcon onClick={handleMicToggle} style={{ cursor: "pointer", color: "#fff" }} />
                                                        )}

                                                        {/* Video Toggle */}
                                                        {isVideoOn ? (
                                                            <VideocamRoundedIcon onClick={handleVideoToggle} style={{ cursor: "pointer", color: "#fff" }} />
                                                        ) : (
                                                            <VideocamOffRoundedIcon onClick={handleVideoToggle} style={{ cursor: "pointer", color: "#fff" }} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="device-select">
                                                    {/* Camera Dropdown */}
                                                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                                                        <InputLabel>Camera</InputLabel>
                                                        <Select
                                                            value={selectedCamera}
                                                            label="Camera"
                                                            onChange={(e) => setSelectedCamera(e.target.value)}
                                                            sx={{
                                                                bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1,
                                                                '& .MuiSelect-select': { color: 'white' },
                                                                '& .MuiList-root': { color: 'white', background: '#19193e' }
                                                            }}
                                                        >
                                                            {cameras.length === 0 ? (
                                                                <MenuItem value="">
                                                                    <em>No Camera Found</em>
                                                                </MenuItem>
                                                            ) : (
                                                                cameras.map((camera) => (
                                                                    <MenuItem key={camera.deviceId} value={camera.deviceId}>
                                                                        {camera.label || `Camera ${camera.deviceId}`}
                                                                    </MenuItem>
                                                                ))
                                                            )}
                                                        </Select>
                                                    </FormControl>

                                                    {/* Microphone Dropdown */}
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel>Microphone</InputLabel>
                                                        <Select
                                                            value={selectedMicrophone}
                                                            label="Microphone"
                                                            onChange={(e) => setSelectedMicrophone(e.target.value)}
                                                            InputProps={{
                                                                style: { color: 'white' },
                                                            }}
                                                            InputLabelProps={{ style: { color: 'white' } }}
                                                            sx={{
                                                                mb: 2, bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1,
                                                                '& .MuiSelect-select': { color: 'white' },
                                                                '& .MuiList-root': { color: 'white', background: '#19193e' }
                                                            }}
                                                        >
                                                            {microphones.length === 0 ? (
                                                                <MenuItem value="">
                                                                    <em>No Microphone Found</em>
                                                                </MenuItem>
                                                            ) : (
                                                                microphones.map((microphone) => (
                                                                    <MenuItem key={microphone.deviceId} value={microphone.deviceId}>
                                                                        {microphone.label || `Microphone ${microphone.deviceId}`}
                                                                    </MenuItem>
                                                                ))
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <Divider orientation="vertical" variant="middle" flexItem className="join-divider" />
                                        </div>
                                        <div className="col-4">
                                            <div className="host-details">
                                                {/* Hostname Input */}
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    label="Hostname"
                                                    placeholder="Hostname"
                                                    value={hostname}
                                                    onChange={(e) => setHostname(e.target.value)}
                                                    InputProps={{
                                                        style: { color: 'white' },
                                                    }}
                                                    InputLabelProps={{ style: { color: 'white' } }}
                                                    sx={{ mb: 2, bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                                />

                                                {/* Show Name Input */}
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    label="Show Name"
                                                    placeholder="Show Name"
                                                    value={showName}
                                                    onChange={(e) => setShowName(e.target.value)}
                                                    InputProps={{
                                                        style: { color: 'white' },
                                                    }}
                                                    InputLabelProps={{ style: { color: 'white' } }}
                                                    sx={{ mb: 2, bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                                />

                                                {/* Join Button */}
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleJoin}
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    <span className="text-trans-none">Join</span>
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinStudio;
