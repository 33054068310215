import React from 'react'
import SideBar from '../Components/Sidebar'

const Shows = () => {
    return (
        <div className='theme-bg clr-white' style={({ height: "100vh" }, { display: "flex" })}>
            <SideBar></SideBar>
            <div className='container-fluid overflow-x-none'>
                <div className='row'>
                    My Shows
                </div>
            </div>
        </div>
    )
}

export default Shows