import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken
} from 'amazon-cognito-identity-js';
// import SessionService from '../services/SessionService';

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
};

export const userPool = new CognitoUserPool(poolData);

// SignIn function
export const signIn = (username, password) => {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                // Store tokens in localStorage
                const accessToken = result.getAccessToken().getJwtToken();
                const idToken = result.getIdToken().getJwtToken();
                const refreshToken = result.getRefreshToken().getToken();

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('idToken', idToken);
                localStorage.setItem('refreshToken', refreshToken);

                resolve(result);
            },
            onFailure: (err) => {
                console.log(err)
                reject(err);
            },
        });
    });
};

// Check if the user is authenticated
export const isAuthenticated = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else {
                    const accessToken = session.getAccessToken().getJwtToken();
                    const idToken = session.getIdToken().getJwtToken();
                    const refreshToken = session.getRefreshToken().getToken();

                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('idToken', idToken);
                    localStorage.setItem('refreshToken', refreshToken);

                    resolve(session.isValid());
                }
            });
        } else {
            resolve(false);
        }
    });
};

// Sign-up function
export const signUp = (username, email, password) => {
    return new Promise((resolve, reject) => {
      const attributes = [
        { Name: 'email', Value: email }
      ];
  
      // Use userPool.signUp to register a new user
      userPool.signUp(username, password, attributes, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

// Confirm Sign-up function
export const confirmSignUp = (username, confirmationCode) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

// Restore session from tokens
// export const restoreSession = (cognitoUser) => {
//     const accessToken = new CognitoAccessToken({
//         AccessToken: localStorage.getItem('accessToken'),
//     });
//     const idToken = new CognitoIdToken({
//         IdToken: localStorage.getItem('idToken'),
//     });
//     const refreshToken = new CognitoRefreshToken({
//         RefreshToken: localStorage.getItem('refreshToken'),
//     });

//     const sessionData = {
//         IdToken: idToken,
//         AccessToken: accessToken,
//         RefreshToken: refreshToken,
//     };

//     const userSession = new CognitoUserSession(sessionData);
//     cognitoUser.setSignInUserSession(userSession);
// };

// Function to manually refresh session from the refresh token
// export const refreshSession = (cognitoUser) => {
//     return new Promise((resolve, reject) => {
//         const refreshToken = new CognitoRefreshToken({
//             RefreshToken: localStorage.getItem('refreshToken'),
//         });

//         cognitoUser.refreshSession(refreshToken, (err, session) => {
//             if (err) {
//                 console.error('Error refreshing session:', err);
//                 reject(err);
//             } else {
//                 console.log('Session refreshed:', session);
//                 // Update localStorage with new tokens
//                 localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
//                 localStorage.setItem('idToken', session.getIdToken().getJwtToken());
//                 resolve(session);
//             }
//         });
//     });
// };
